import React, { useEffect } from 'react';

import FloatingButton from '../../components/floating-button/floating-button';
import Header from '../../components/header/header';
import Layout from '../../components/layout';
import useWindowDimensions from '../../utils/hooks/use-window-dimensions';

const ServiceStatutePage = (props) => {

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width) {
            window.location.href = `https://files.billongroup.com/uploads/Regulamin-rachunku.pdf`;
        }
    })

    const scrollButton = {
        link: `https://files.billongroup.com/uploads/Regulamin-rachunku.pdf`,
        label: `Pobierz regulamin`
    }

    const backgroundImage = {
        url: `/uploads/falka_home_0c77d52e79.svg`,
    }
    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />

            <Header
                key={`service-statute-header`}
                title="<h1>Zaraz pobierzesz dokument</h1>"
                subtitle="W razie problemu proszę pobrać klikając w poniższy przycisk"
                backgroundImage={backgroundImage.url}
                backgroundHeight="900px"
                scrollButton={scrollButton}
                textBoxCustomClasses="lg:w-full flex flex-col items-center"
            ></Header>
        </Layout>
    )
}

export default ServiceStatutePage;